<template>
  <div class="setup bsbb p20">
    <van-nav-bar left-arrow @click-left="onClickLeft" :border="false">
      <template #title>
        <div class="top-nav df aic jcsb">
          <div class="df aic jcc">
            <span style="font-weight: bold; font-size: 20px; line-height: normal">设置中心</span>
          </div>
        </div>
      </template>
    </van-nav-bar>
    <!-- <van-nav-bar title="设置中心" left-arrow @click-left="onClickLeft" :border="false" /> -->
    <div class="list df fdc bsbb">
      <div class="df aic jcsb item" @click="$router.push('/amendPass')">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting1.png" class="mr20" />
          <span>修改密码</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="df aic jcsb item" @click="$router.push('/amendPass1')">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting1.png" class="mr20" />
          <span>修改支付密码</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <!-- <div class="df aic jcsb item" @click="$router.push('/order')">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting2.png" class="mr20" />
          <span>支付设置</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="df aic jcsb item">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting3.png" class="mr20" />
          <span>信息通知设置</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="df aic jcsb item">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting4.png" class="mr20" />
          <span>清除缓存</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="df aic jcsb item">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting5.png" class="mr20" />
          <span>用户协议</span>
        </div>
        <van-icon name="arrow" />
      </div>
      <div class="df aic jcsb item" @click="$router.push('/setupCenter')">
        <div class="font-size-24 df aic">
          <img src="../../assets/images/xg/setting6.png" class="mr20" />
          <span>热线电话</span>
        </div>
        <van-icon name="arrow" />
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-icon {
  font-size: 32px;
}

::v-deep .van-nav-bar__arrow {
  font-size: 46px !important;
}

::v-deep .van-nav-bar__content {
  height: 70px;
  line-height: 70px;
  box-shadow: none !important;
}

.setup {
  width: 100%;
  height: 100%;
  background: #fff;

  .list {
    padding: 0 30px;
    margin-left: 30px;
    width: 694px;
    height: 1100px;
    opacity: 1;
    border-radius: 20px;
    background: rgba(255, 255, 255, 1);

    .item {
      border-bottom: 1px solid rgb(241, 240, 240);
      padding: 30px 0;
      line-height: 64px;
    }

    img {
      width: 60px;
      height: 60px;
      opacity: 1;
    }

    .van-icon {
      font-size: 16px;
    }
  }
}
</style>